module.exports = {
  JOIN_ATRIUM: 'JOIN_ATRIUM',
  CREATE_PROJECT: 'CREATE_PROJECT',
  LIKE_PROJECT: 'LIKE_PROJECT',
  COMMENT_PROJECT: 'COMMENT_PROJECT',
  CREATE_POLL: 'CREATE_POLL',
  ANSWER_POLL: 'ANSWER_POLL',
  UPLOAD_LEARNING_RESOURCE: 'UPLOAD_LEARNING_RESOURCE',
  LIKE_LEARNING_RESOURCE: 'LIKE_LEARNING_RESOURCE',
  COMMENT_LEARNING_RESOURCE: 'COMMENT_LEARNING_RESOURCE',
  ISSUE_BADGE: 'ISSUE_BADGE',
  CREATE_DISCUSSION: 'CREATE_DISCUSSION',
  PARTICIPATE_DISCUSSION: 'PARTICIPATE_DISCUSSION',
  LIKE_DISCUSSION: 'LIKE_DISCUSSION'
}