export const GET_ERRORS = 'GET_ERRORS'
export const SET_ERRORS = 'SET_ERRORS'
export const UNSET_ERRORS = 'UNSET_ERRORS'
export const USER_LOADING = 'USER_LOADING'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_RECENT_TWEETS = 'SET_RECENT_TWEETS'
export const SET_CURRENT_GITHUB_USERS = 'SET_CURRENT_GITHUB_USERS'
export const SET_GITHUB_HANDLE_FOR_USER = 'SET_GITHUB_HANDLE_FOR_USER'
export const SET_TWITTER_HANDLE_FOR_USER = 'SET_TWITTER_HANDLE_FOR_USER'
export const SET_BLOCKCHAIN_BLOCK_NUMBER = 'SET_BLOCKCHAIN_BLOCK_NUMBER'
export const SET_ACTIVE_POLLS = 'SET_ACTIVE_POLLS'
export const SET_CLOSED_OR_VOTED_ON_POLLS = 'SET_CLOSED_OR_VOTED_ON_POLLS'
export const SET_BLOCKCHAIN_DECISIONS = 'SET_BLOCKCHAIN_DECISIONS'

export const SET_ALL_PROJECTS = 'SET_ALL_PROJECTS'
export const UPDATE_PROJECT_LIST = 'UPDATE_PROJECT_LIST'

export const SET_USER_ACTIVITY = 'SET_USER_ACTIVITY'

export const SET_LEARNING_RESOURCES = 'SET_LEARNING_RESOURCES'
